<template>
<div>
    <div v-if="drivers.length > 0">
        <DriverItem @remove="removeItem(key)" @update="getDrivers" :key="driver._id" v-for="(driver, key) of drivers" :driver="driver"/>
    </div>
    <div class="noDriversMessage" v-else>
        <div class="icon"><i class="fa-regular fa-id-card"></i></div>
        <div class="message">No drivers found</div>
    </div>
    <div class="addDriverButton">
        <button @click="addDriver">Add driver</button>
    </div>
</div>
</template>

<script>
import DriverItem from './DriverItem.vue'
    export default {
        props: ['carrier'],
        components: {
            DriverItem
        },
        data() {
            return {
                drivers: []
            }
        },
        methods: {
            removeItem(key) {
                this.drivers.splice(key, 1);
            },
            addDriver() {
                this.drivers.push({
                    edit: true,
                    name: 'Unknown',
                    carrier: this.carrier._id
                });
            },
            getDrivers() {
                this.ajax('getListOfDrivers', {
                    url: `/carrier/drivers/${this.carrier._id}`,
                    method: 'GET'
                }, (err, body) => {
                    if(err) return;
                    this.drivers = body;
                });
            }
        },
        mounted() {
            this.getDrivers();
        }
    }
</script>

<style lang="scss" scoped>
.noDriversMessage {
    padding: 30px 20px;
    display: grid;
    justify-items: center;
    row-gap: 10px;
    .icon {
        font-size: 40px;
        opacity: 0.9;
    }
    .message {
        text-align: center;
        font-size: 14px;
    }
}

.addDriverButton {
    button {
        width: 100%;
        height: 44px;
        background: transparent;
        border: 0;
        border-top: 1px solid $borderColor;
        color: $buttonColor;
        padding: 0;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
    }
}
</style>