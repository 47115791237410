<template>
    <div class="addCarrier">

        <div class="carrierHeader">
            <h2 class="_name"><button v-if="showBackBtn" @click="back" class="default small reverse"><i class="fas fa-arrow-left"></i></button>{{ carrier.name }}</h2>
            <span v-if="isUserPermitted('carriers', 'edit') && !isDisabled">
                <a href="" @click.prevent="isEdit = true" v-if="!isEdit">Edit</a>
                <a href="" class="default small round" @click.prevent="saveDetails" v-else>Save</a>
            </span>
        </div>

        <div class="sections">
            <div class="categories">
                <button class="default small round" 
                :key="key" v-for="(item, key) of sections" 
                :class="{ 'active' : key == selectedSection }"
                @click="selectedSection = key">{{ item.name }}</button>
            </div>
            <div class="componentContent">
                <component v-bind:is="sections[selectedSection].component" :errors="errors" :isEdit="isEdit" :viewOnly="isDisabled" :carrier="carrier" @save="saveDetails"></component>
            </div>
        </div>


        <div class="carrierRegistration" v-if="!isDisabled">
            <h2>Carrier Account</h2>
            <div v-if="carrier.credentials" class="carrierAccount">
                <div class="email">{{ carrier.credentials.email }}</div>
                <div class="action">
                    <button @click.prevent="$emit('deleteAccount', carrier._id);"><span>Delete account</span><i class="fa-solid fa-trash-can"></i></button>
                </div>
            </div>
            <div class="carrierAccount" v-else>
                <div class="action">
                    <button @click.prevent="$emit('invite', carrier._id, 'email');"><span>Invite via Email</span><i class="fa-solid fa-envelope"></i></button>
                    <button @click.prevent="$emit('invite', carrier._id, 'sms');"><span>Invite via SMS</span><i class="fa-solid fa-comment-sms"></i></button>
                    <button @click.prevent="$emit('copyInvite', carrier._id)"><span>Copy invitation</span><i class="fa-solid fa-copy"></i></button>
                </div>
            </div>
        </div>


        <button class="default deleteBtn" v-if="isEdit" @click="remove">Remove carrier</button>

    </div>
</template>

<script>

    import CarrierDetails from './CarrierDetails.vue';
    import CarrierDocuments from './CarrierDocuments.vue';
    import CarrierDrivers from './CarrierDrivers.vue';
    import CarrierTrucks from './CarrierTrucks.vue';

    export default {
        props: ['carrier', 'disabled', 'showBackBtn'],
        components: {
            CarrierDetails,
            CarrierDocuments,
            CarrierDrivers,
            CarrierTrucks
        },
        data() {
            return {
                sections: [
                    { name: 'Details', component: 'CarrierDetails' },
                    { name: 'Documents', component: 'CarrierDocuments' },
                    { name: 'Trucks', component: 'CarrierTrucks' },
                    { name: 'Drivers', component: 'CarrierDrivers' },
                ],
                selectedSection: 0,
                errors: {},
                isEdit: false,
                isDisabled: this.disabled || false
            }
        },
        methods: {
            remove() {
                this.isEdit = false;
                this.$emit('remove', this.carrier._id);
            },
            back() {
                this.$emit('back');
            },
            saveDetails() {
                this.errors = {};

                let body = JSON.parse(JSON.stringify(this.carrier));
                
                this.$axios.put('/carriers', body)
                .then(data => {
                    let body = data.data;
                    this.$notify({
                        type: 'info',
                        text: 'Carrier details have been updated successfully'
                    });
                    this.isEdit = false;
                    this.$emit('updated');
                })
                .catch(err => {
                    let response = err.response;

                    if(response && response.data) {
                        let body = response.data;
                        if(body.errors) this.errors = body.errors;
                        else if(body.message) alert(body.message);
                        else alert("Something went wrong. Please, try again!");
                    } else {
                        alert("Something went wrong. Please, try again!");
                    }

                });
            }
        },
    }
</script>

<style lang="scss" scoped>

.sections {
    display: grid;
    row-gap: 20px;
    .categories {
        display: flex;
        column-gap: 10px;
        button {
            border: 1px solid $buttonColor;
            background: $bg;
            color: $buttonColor;
            &.active {
                background: $buttonColor;
                color: $buttonText;
            }
        }
    }
}

.componentContent {
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    background: $bgSecondary;
}

.addCarrier {
    display: grid;
    row-gap: 30px;
}


.carrierHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    ._name {
        display: grid;
        align-items: center;
        grid-template-columns: auto auto;
        column-gap: 20px;
    }
}


.deleteBtn {
    background: $error;
}


.carrierRegistration {
    
}

.carrierAccount {
    display: grid;
    row-gap: 5px;
    margin-top: 10px;
    .email {
        font-size: 16px;
    }
    .action {
        display: grid;
        row-gap: 10px;
        button {
            height: 36px;
            display: flex;
            text-transform: uppercase;
            justify-content: space-between;
            padding: 0 10px;
            align-items: center;
            border: 0;
            background: $bgSecondary;
            box-shadow: $boxShadow;
            border-radius: $borderRadius;
            border: 1px solid $borderColor;
            transition: ease 0.3s;
            &:hover {
                transform: scale(1.01)
            }
        }
    }
}
</style>