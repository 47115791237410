<template>
    <div class="truck">
        <div class="name">{{ truck.name }}</div>
        <div class="details"><span class="truckDetails">{{ truckDetails }}</span></div>

        <div class="actionBtns">
            <button @click="editTruck"><i class="fa-solid fa-pencil"></i></button>
            <button @click="deleteTruck" class="remove"><i class="fa-solid fa-trash-can"></i></button>
        </div>

        <div class="editTruck" v-if="truck.edit">
            <Input name="Name" placeholder="Name" :error="errors.name" v-model="truckData.name"/>
            <Input name="License plate" placeholder="License plate" :error="errors.licensePlate" v-model="truckData.licensePlate"/>

            <div class="input">
                <div class="_header">
                    <label>Truck Driver</label>
                    <span class="_error">{{ errors.driver }}</span>
                </div>
                <select v-model="truckData.driver">
                    <option :value="null">Select driver</option>
                    <option :key="driver._id" :value="driver._id" v-for="driver of drivers">{{ driver.name }} {{ driver.truck[0] ? `[Currently: ${driver.truck[0].name}]` : '' }}</option>
                </select>
            </div>

            <div class="actionButtons rowOfTwo">
                <button class="default medium reverse" @click="cancelEdit">Cancel</button>
                <button class="default medium" @click="saveChanges">Save changes</button>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        props: ['truck', 'drivers'],
        data() {
            return {
                truckData: JSON.parse(JSON.stringify(this.truck)),
                errors: {}
            }
        },
        computed: {
            truckDetails() {
                if(!this.truck.driverDetails) return 'No Driver Assigned';
                if(!this.truck.driverDetails[0]) return 'No Driver Assigned';

                return this.truck.driverDetails[0].name;
            }
        },
        methods: {
            deleteTruck() {

                let confirmDelete = confirm("Do you really want to delete this truck?");
                if(!confirmDelete) return;

                if(!this.truck._id) {
                    this.$emit('remove');
                    return;
                }

                this.ajax('deleteTruck', {
                    url: `/carrier/trucks/${this.truck._id}`,
                    method: 'DELETE',
                }, (err, body) => {
                    if(err) {
                        alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }
                    this.$emit('update');
                });
            },
            editTruck() {
                this.truck.edit = true;
            },
            cancelEdit() {
                if(!this.truck._id) {
                    this.$emit('remove');
                    return;
                } 
                this.truckData = JSON.parse(JSON.stringify(this.truck));
                this.truck.edit = false;
            },
            saveChanges() {
                this.errors = {};
                this.ajax('saveTruckDetails', {
                    url: '/carrier/trucks',
                    method: 'POST',
                    data: this.truckData
                }, (err, body) => {
                    if(err) {
                        if(body.errors) this.errors = body.errors;
                        else alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }
                    this.truck.edit = false;
                    this.$emit('update');
                });
            }
        },
    }
</script>

<style lang="scss" scoped>

.truck {
    border-bottom: 1px solid $borderColor;
    padding: 20px;
    display: grid;
    row-gap: 5px;
    position: relative;
    &:last-child {
        border: 0;
    }
    .name {
        font-weight: bold;
        text-transform: uppercase;
    }
    .details {
        font-size: 12px;
        opacity: 0.9;
    }
}

.editTruck {
    border-top: 1px solid $borderColor;
    padding-top: 20px;
    margin-top: 10px;
    display: grid;
    row-gap: 10px;
}

.rowOfTwo {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 10px;
}


.actionBtns {
    position: absolute;
    top: 20px;
    right: 20px;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 7px;
    button {
        border: 0;
        border-bottom: 3px solid $borderColor;
        width: 24px;
        height: 24px;
        border-radius: 5px;
        font-size: 10px;
        color: $text;
        background: $buttonColor2;
        &.remove {
            color: #C0392B;
        }
    }
}
</style>