<template>
    <div class="fileUploader">

        <div class="header">
            <div>{{ selectedFiles.length }} {{ selectedFiles.length == 1 ? 'file' : 'files'  }}</div>
            <button class="default small round" @click="openFilePicker" v-if="!disabled"><i class="fas fa-plus "></i></button>
        </div>

        <div class="fileList" v-if="selectedFiles.length > 0">
            <div class="file" :key="key" v-for="(file, key) of selectedFiles">
                <div class="_name" v-if="isEdit">
                    <select v-model="file.categoryName" class="invisibleInput">
                        <option :value="file.name">{{ file.name }}</option>
                        <option :key="key" v-for="(cat, key) of fileCategories">{{ cat }}</option>
                    </select>
                </div>
                <div class="_name" v-else><a :href="apiURL + file.path" target="_blank">{{ file.categoryName || file.name }}</a></div>

                <div class="_status">
                    <div class="bar" v-if="file.status < 100">
                        <div class="line" :style="`width: ${file.status}%`"></div>
                    </div>
                </div>

                <button class="_action" v-if="!disabled" @click="abortOrCancel(file, key)"><i class="fas fa-times"></i></button>
            </div>
        </div>

        <div class="clickMessage" v-else-if="disabled">
            <span>No files found</span>
        </div>

        <div class="clickMessage" v-else>
            <button class="default small round" @click="openFilePicker" :disabled="disabled">Upload files</button>
        </div>

        <input type="file" name="file" ref="files" @change="fileSelected" hidden multiple>

    </div>
</template>

<script>
import { mapGetters } from 'vuex';
    export default {
        props: ['modelValue', 'isEdit', 'disabled', 'fileCats'],
        data() {
            return {
                selectedFiles: this.modelValue || [],
                fileCategories: this.fileCats || ['Rate confirmation', 'BOL', 'POD', 'Other']
            }
        },
        watch: {
            selectedFiles: {
                deep: true,
                handler() {
                    this.$emit('update:modelValue', this.selectedFiles);
                    if(this.isUploaded && !this.isEdit) { this.$emit('completed'); }
                }
            },
            isUploaded() {
                if(this.isUploaded && !this.isEdit) { this.$emit('completed'); }
            }
        },
        computed: {
            ...mapGetters(['token']),
            isUploaded() {
                let uploaded = true;
                for(const file of this.selectedFiles) {
                    if(file.status < 100 || !file.path) uploaded = false;
                }

                return uploaded;
            },
            apiURL() {
                return process.env.VUE_APP_API_URL;
            }
        },
        methods: {
            openFilePicker() {
                if(this.disabled) return;
                this.$refs['files'].click();
            },
            abortOrCancel(file, key) {
                try {
                    if(file.xmlReq) file.xmlReq.abort();
                } catch(err) {  }

                this.selectedFiles.splice(key, 1);
            },
            fileSelected() {
                if(this.disabled) return;
                let files = this.$refs['files'].files;
                this.uploadFiles(files);
            },
            uploadFiles(files) {
                
                if(this.disabled) return;

                const url = process.env.VUE_APP_API_URL + '/files/upload'; // uploading url
                for(const file of files) {

                    let fileIndex = this.selectedFiles.push({
                        name: file.name,
                        categoryName: file.name,
                        size: (file.size / 1048576).toFixed(2),
                        status: 0,
                        path: null,
                        xmlReq: new XMLHttpRequest()
                    });

                    let fileItem = this.selectedFiles[fileIndex - 1];

                    let formData = new FormData();
                    formData.append("document", file);

                    fileItem.xmlReq = new XMLHttpRequest();
                    fileItem.xmlReq.upload.addEventListener("progress", (event) => {
                        var percent = Math.round((event.loaded / event.total) * 100);
                        fileItem.status = percent;
                    }, false);

                    fileItem.xmlReq.addEventListener("load", (event) => {
                        let res = JSON.parse(event.target.response);
                        fileItem.status = 100;
                        fileItem.path = res.path;
                        delete fileItem.xmlReq;
                    }, false);

                    fileItem.xmlReq.open("POST", url);
                    fileItem.xmlReq.setRequestHeader('authorization', this.token);
                    fileItem.xmlReq.send(formData);
                
                }

                this.$refs['files'].value = '';

            }
        },
    }
</script>

<style lang="scss" scoped>
.file {
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto 30px;
    height: 50px;
    align-items: center;
    column-gap: 20px;
    padding: 10px 20px;
    border-bottom: 1px solid $borderColor;
    &:last-child {
        border: 0;
    }
    ._action {
        width: 30px;
        height: 30px;
        border: 0;
        background: transparent;
        color: $error;
    }
}

._status {
    .bar {
        width: 75px;
        height: 10px;
        border: 1px solid #eee;
        border-radius: 5px;
        overflow: hidden;
        position: relative;
        .line {
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
            background: $buttonColor;
        }
    }
}

.fileUploader {
    display: grid;
}

.header {
    padding: 0 20px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $bg;
    border-bottom: 1px solid $borderColor;
    button {
        padding: 0;
        width: 34px;
        height: 34px;
        background: transparent;
        color: $buttonColor;
    }
}

.clickMessage {
    padding: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>