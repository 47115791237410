<template>
    <div class="detailsTable">

        <div class="row">
            <div class="title">Name</div>
            <div class="value"><input :disabled="!isEdit" class="invisibleInput textRight" :class="{ 'error' : errors.name }" type="text" v-model="carrier.name" placeholder="Carrier name"></div>
        </div>

        <div class="row">
            <div class="title">MC #</div>
            <div class="value"><input :disabled="!isEdit" class="invisibleInput textRight" :class="{ 'error' : errors.mcNumber }" type="text" v-model="carrier.mcNumber" placeholder="MC #"></div>
        </div>

        <div class="row">
            <div class="title">Dispatch email address</div>
            <div class="value"><input :disabled="!isEdit" class="invisibleInput textRight" :class="{ 'error' : errors.email }" type="text" v-model="carrier.email" placeholder="Email address for dispatch"></div>
        </div>

        <div class="row">
            <div class="title">Invoice email address</div>
            <div class="value"><input :disabled="!isEdit" class="invisibleInput textRight" :class="{ 'error' : errors.invoiceEmail }" type="text" v-model="carrier.invoiceEmail" placeholder="Email address for invoices"></div>
        </div>

        <div class="row">
            <div class="title">Phone</div>
            <div class="value"><input :disabled="!isEdit" class="invisibleInput textRight" :class="{ 'error' : errors.phone }" type="text" v-model="carrier.phone" placeholder="Phone"></div>
        </div>

        <div class="row">
            <div class="title">Representative</div>
            <div class="value">
                
                <select v-model="carrier.representative" v-if="isEdit" class="invisibleInput textRight" :class="{ 'error' : errors.representative }">
                    <option :value="null">-- No one --</option>
                    <option :key="key" v-for="(item, key) of dispatchers" :value="item._id">{{ item.name }}</option>
                </select>

                <span v-else>{{ representative }}</span>
                
            </div>
        </div>

        <div class="row" v-if="!viewOnly">
            <div class="title">Carrier percentage</div>
            <div class="value"><input :disabled="!isEdit" class="invisibleInput textRight" :class="{ 'error' : errors.percent }" type="text" v-model="carrier.percent" placeholder="Percentage"></div>
        </div>

        <div class="row">
            <div class="title">Truck's target</div>
            <div class="value"><input :disabled="!isEdit" class="invisibleInput textRight" :class="{ 'error' : errors.truckTarget }" type="text" v-model="carrier.truckTarget" placeholder="Truck's target"></div>
        </div>

        <div class="row" v-if="!viewOnly">
            <div class="title">Registered</div>
            <div class="value">{{ carrier.credentials ? 'Yes' : 'No' }}</div>
        </div>

        <div class="carrierAddress" v-if="!viewOnly">
            <div class="title">Carrier address</div>
            <textarea :disabled="!isEdit" v-model="carrier.address" placeholder="Carrier address"></textarea>
        </div>


    </div>
</template>

<script>
import { mapGetters } from 'vuex';
    export default {
        props: ['carrier', 'isEdit', 'errors', 'viewOnly'],
        data() {
            return {
                dispatchers: []
            }
        },
        computed: {
            ...mapGetters(['users']),
            invitationLink() {
                if(!this.carrier._id) return null;
                return (process.env.VUE_APP_CARRIER_INVITATION_LINK || 'https://example.com') + "/invitation/" + this.carrier._id;
            },
            representative() {
                if(!this.carrier.representative) return '-';
                for(const item of this.users) {
                    if(item._id === this.carrier.representative) return item.name;
                }
                return '-';
            }
        },
        methods: {
            async getDispatchTeam() {
                this.dispatchers = await new Promise(resolve => {
                    this.$axios.get('/users/permissions/loads/add')
                    .then(data => {
                        return resolve(data.data);
                    })
                    .catch(err => {
                        return resolve([]);
                    });
                });
            }
        },
        mounted () {
            this.getDispatchTeam();
            if(!this.carrier.representative) this.carrier.representative = null;
        },
    }
</script>

<style lang="scss" scoped>


.detailsTable {
    .row {
        width: 100%;
        display: grid;
        height: 50px;
        align-items: center;
        padding: 0 20px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        &:nth-child(2n) {
            background: $bg;
        }
        .title {
            font-weight: bold;
        }
        .value {
            text-align: right;
        }
    }
}

.carrierAddress {
    padding: 20px;
    width: 100%;
    display: grid;
    row-gap: 10px;
    border-top: 1px solid $borderColor;
    .title {
        font-weight: bold;
    }
    textarea {
        padding: 10px;
        resize: none;
        width: 100%;
        height: 100px;
        margin: 0;
        border: 0;
        background: $bg;
        border: 1px solid $borderColor;
        border-radius: $borderRadius;
    }
}
</style>