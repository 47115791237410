<template>
    <div class="driver">
        <div class="name">{{ driver.name }} {{ driver.lastname }}</div>
        <div class="details">{{ driver.phone }} • <span class="truckDetails">{{ truckDetails }}</span></div>

        <div class="actionBtns">
            <button @click="editDriver"><i class="fa-solid fa-pencil"></i></button>
            <button @click="deleteDriver" class="remove"><i class="fa-solid fa-trash-can"></i></button>
        </div>

        <div class="editDriver" v-if="driver.edit">
            <Input name="Name" placeholder="Name" :error="errors.name" v-model="driverData.name"/>

            <div class="rowOfTwo">
                <Input name="Email address" placeholder="Email address" :error="errors.email" v-model="driverData.email"/>
                <Input name="Phone #" placeholder="Phone #" :error="errors.phone" v-model="driverData.phone"/>
            </div>

            <div class="actionButtons rowOfTwo">
                <button class="default medium reverse" @click="cancelEdit">Cancel</button>
                <button class="default medium" @click="saveChanges">Save changes</button>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        props: ['driver'],
        data() {
            return {
                driverData: JSON.parse(JSON.stringify(this.driver)),
                errors: {}
            }
        },
        computed: {
            truckDetails() {
                if(!this.driver.truck) return 'No Truck Assigned';
                if(!this.driver.truck[0]) return 'No Truck Assigned';

                return this.driver.truck[0].name;
            }
        },
        methods: {
            deleteDriver() {

                let confirmDelete = confirm("Do you really want to delete this driver?");
                if(!confirmDelete) return;

                if(!this.driver._id) {
                    this.$emit('remove');
                    return;
                }

                this.ajax('deleteDriver', {
                    url: `/carrier/drivers/${this.driver._id}`,
                    method: 'DELETE',
                }, (err, body) => {
                    if(err) {
                        alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }
                    this.$emit('update');
                });
            },
            editDriver() {
                this.driver.edit = true;
            },
            cancelEdit() {
                if(!this.driver._id) {
                    this.$emit('remove');
                    return;
                } 
                this.driverData = JSON.parse(JSON.stringify(this.driver));
                this.driver.edit = false;
            },
            saveChanges() {
                this.errors = {};
                this.ajax('saveDriverDetails', {
                    url: '/carrier/drivers',
                    method: 'POST',
                    data: this.driverData
                }, (err, body) => {
                    if(err) {
                        if(body.errors) this.errors = body.errors;
                        else alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }
                    this.driver.edit = false;
                    this.$emit('update');
                });
            }
        },
    }
</script>

<style lang="scss" scoped>

.driver {
    border-bottom: 1px solid $borderColor;
    padding: 20px;
    display: grid;
    row-gap: 5px;
    position: relative;
    &:last-child {
        border: 0;
    }
    .name {
        font-weight: bold;
    }
    .details {
        font-size: 12px;
        opacity: 0.9;
    }
}

.editDriver {
    border-top: 1px solid $borderColor;
    padding-top: 20px;
    margin-top: 10px;
    display: grid;
    row-gap: 10px;
}

.rowOfTwo {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 10px;
}


.actionBtns {
    position: absolute;
    top: 20px;
    right: 20px;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 7px;
    button {
        border: 0;
        border-bottom: 3px solid $borderColor;
        width: 24px;
        height: 24px;
        border-radius: 5px;
        font-size: 10px;
        color: $text;
        background: $buttonColor2;
        &.remove {
            color: #C0392B;
        }
    }
}
</style>