<template>
<div>
    <div v-if="trucks.length > 0">
        <TruckItem :drivers="drivers" @remove="removeItem(key)" @update="getTrucks" :key="truck._id" v-for="(truck, key) of trucks" :truck="truck"/>
    </div>
    <div class="noTrucksMessage" v-else>
        <div class="icon"><i class="fa-solid fa-truck-fast"></i></div>
        <div class="message">No trucks found</div>
    </div>
    <div class="addTruckButton">
        <button @click="addTruck">Add truck</button>
    </div>
</div>
</template>

<script>
import TruckItem from './TruckItem.vue'
    export default {
        props: ['carrier'],
        components: {
            TruckItem
        },
        data() {
            return {
                trucks: [],
                drivers: []
            }
        },
        methods: {
            getDrivers() {
                this.ajax('getListOfDrivers', {
                    url: `/carrier/drivers/${this.carrier._id}`,
                    method: 'GET'
                }, (err, body) => {
                    if(err) return;
                    this.drivers = body;
                });
            },
            removeItem(key) {
                this.trucks.splice(key, 1);
            },
            addTruck() {
                this.trucks.push({
                    edit: true,
                    name: 'Unknown',
                    carrier: this.carrier._id
                });
            },
            getTrucks() {
                this.ajax('getListOfTrucks', {
                    url: `/carrier/trucks/${this.carrier._id}`,
                    method: 'GET'
                }, (err, body) => {
                    if(err) return;
                    this.trucks = body;
                });
            }
        },
        mounted() {
            this.getTrucks();
            this.getDrivers();
        }
    }
</script>

<style lang="scss" scoped>
.noTrucksMessage {
    padding: 30px 20px;
    display: grid;
    justify-items: center;
    row-gap: 10px;
    .icon {
        font-size: 40px;
        opacity: 0.9;
    }
    .message {
        text-align: center;
        font-size: 14px;
    }
}

.addTruckButton {
    button {
        width: 100%;
        height: 44px;
        background: transparent;
        border: 0;
        border-top: 1px solid $borderColor;
        color: $buttonColor;
        padding: 0;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
    }
}
</style>