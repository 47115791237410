<template>
    <div v-if="viewOnly">
        <FileUploder :fileCats="['COI', 'W9', 'Other']" v-model="carrier.documents" :disabled="true" :isEdit="isEdit" @completed="saveCarrier"/>
    </div>
    <div v-else>
        <FileUploder :fileCats="['COI', 'W9', 'Other']" v-model="carrier.documents" :disabled="!this.isUserPermitted('carriers', 'edit')" :isEdit="isEdit" @completed="saveCarrier"/>
    </div>
</template>

<script>
import FileUploder from '../FileUploader.vue'
    export default {
        props: ['carrier', 'isEdit', 'viewOnly'],
        components: {
            FileUploder
        },
        data() {
            return {
                
            }
        },
        methods: {
            saveCarrier() {
                this.$emit('save');
            }
        },
        mounted() {
            if(!this.carrier.documents) this.carrier.documents = [];
        }
    }
</script>

<style lang="scss" scoped>

</style>